// variable

$dark-red-color: #50cd89;
$light-red-color: #47be7d;

$btn-bg-red-color: #50cd89;

Button[type='submit'] {
  background-color: $dark-red-color !important;
  font-size: 16px;
  padding: 10px 25px !important;
  color: #fff !important;
}

.sbmt-btn {
  background-color: $dark-red-color !important;
  padding: 10px 25px !important;
  color: #fff !important;
}

Button:hover {
  background-color: $light-red-color !important;
  color: #fff;
}

.searchWrapper {
  border: 0 !important;
}

.sbmt-btn {
  background-color: $dark-red-color !important ;
  font-size: 16px;
  padding: 10px 25px !important;
  color: #fff;
}

.sbmt-btn:hover {
  background-color: $light-red-color !important;
  color: #fff;
}

#kt_login_signin_form {
  h1 {
    font-size: 30px;
  }

  h1,
  a {
    color: $dark-red-color;
  }
}

#kt_modal_add_Customer,
#kt_modal_add_message,
#kt_modal_add_ticketmanagement,
#kt_modal_add_distributor,
#kt_modal_add_customermanagement,
#kt_modal_add_claimmanagement,
#kt_modal_add_sendReceiveSparePart, 
#kt_modal_add_productreturnmanagement {
  .modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    height: 100vh;
    @media screen and (min-width: 991px) {
      width: 850px;
    }
    .modal-content {
      height: 100vh;
    }
  }

  .modal-body h2 {
    background-color: #f1f1f1;
    font-size: 18px !important;
    color: #505050 !important ;
    padding: 15px 20px;
    margin-bottom: 15px;
  }

  #kt_modal_add_servicecenter_scroll > div,
  #kt_modal_add_customersupport_scroll > div,
  #kt_modal_add_sendReceiveSparePart_scroll > div {
    padding: 8px 30px;
  }

  div label {
    font-size: 14px !important;
    text-transform: uppercase;
    color: #6a6a6a;
    font-weight: 500 !important;
    padding-left: 0px;
  }

  div input::-webkit-input-placeholder {
    opacity: 0.5 !important;
  }

  Button.btn-primary:hover {
    background-color: $light-red-color !important;
  }
}

.add-service-form-tab {
  ul {
    background-color: #f1f1f1;

    li {
      font-size: 16px !important;
      font-weight: 500;
      color: #000;

      Button {
        padding: 12px 35px;
      }
    }
  }

  .nav-tabs .nav-link.active {
    background-color: transparent;
    color: $dark-red-color;
    border: 0;
    border-bottom: 3px solid $dark-red-color;
  }
}

.modal-dialog {
  .modal-body form label {
    font-size: 14px !important;
    text-transform: uppercase;
    color: #4a4a4a;
  }

  #kt_modal_add_distributor,
  #kt_modal_add_customersupport,
  #kt_modal_add_supportagent,
  #kt_modal_add_categorymanagement,
  #kt_modal_add_modelmanagement,
  #kt_modal_add_productmanagement,
  #kt_modal_add_partmanagement,
  #kt_modal_add_customermanagement {
    .modal-header h2 {
      color: $dark-red-color !important;
    }
  }

  // added by Dharmendra on 04/04/2023
  .custom_select {
    .css-13cymwt-control {
      background-color: inherit;
      border: none;
    }

    .css-t3ipsp-control {
      border: none;
      box-sizing: none;
      background-color: none;
    }

    .Select__indicator-separator,
    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }
  // end css

  div input::-webkit-input-placeholder {
    opacity: 0.5;
  }

  Button.btn-primary:hover {
    background-color: $light-red-color !important;
  }
}

.add-service-form-tab {
  ul {
    background-color: #f1f1f1;
    li {
      font-size: 16px !important;
      font-weight: 500;
      color: #000;
      Button {
        padding: 12px 35px;
      }
    }
  }
  .nav-tabs .nav-link.active {
    background-color: transparent;
    color: $dark-red-color;
    border: 0;
    border-bottom: 3px solid $dark-red-color;
  }
}

.table.gy-5 td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.menu-item .menu-link {
  padding: 5px !important;
}
.menu-item .menu-link .view-icon {
  height: 18px;
}

// end css
.rmsc .dropdown-container {
  background-color: #f5f8fa !important;
  border: 0 !important;
  height: 43px !important;
  padding-top: 5px !important;
}
.multiSelectContainer {
  background-color: var(--kt-input-solid-bg-focus) !important;
  border-color: var(--kt-input-solid-bg-focus) !important;
  min-height: 42px !important;
  border-radius: 6px !important;
  padding: 3px 5px !important;
  // background-image:url('/public/media/icons/duotune/arrows/down-arrow.png');
  // background-repeat: no-repeat;
  // background-position-x: 95%;
  // background-position-y: 20%;
}

// .multiSelectContainer .searchWrapper::after {
//   content: '\02C5';
// }

.Select__control {
  border: none;
  background: #f5f8fa !important;
  box-shadow: none !important;
  padding: 4px 0;
}
.css-1jqq78o-placeholder {
  font-size: 14px !important;
  color: hsl(0deg 0% 40%) !important;
}

.css-13cymwt-control {
  border: none !important;
}

.css-t3ipsp-control {
  box-shadow: none !important;
  border-color: none !important;
}

.css-t3ipsp-control:hover {
  border: none !important;
}

input::placeholder {
  font-size: 14px !important;
  color: hsl(0deg 0% 40%) !important;
}
.form-check-custom.form-check-sm .form-check-input {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
.app-main {
  min-height: calc(100vh - 70px);
}

// .table tr:first-child, .table th:first-child, .table td:first-child {
//   padding-left: 15px;
// }
.table tr:last-child,
.table th:last-child,
.table td:last-child {
  padding-right: 15px !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > *{color:inherit !important}

.table-striped > tbody > tr:hover,
.table-striped > tbody > tr:nth-of-type(odd) > *:hover {
  background-color: #eef3f5 !important;
}

//. Added css by Dharmendra on 10/04/2023
.dlt-mdl-icon i {
  font-size: 60px;
}
.deletemessage {
  background-color: #e8fff3;
  font-size: 16px;
  padding: 12px 15px;
}
// end css by Dharmendra on 10/04/2023

.detailmodel {
  .modal-content {
    background-color: #f5f8fa !important;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
}
.show.menu-dropdown > .menu-sub-dropdown,
.menu-sub-dropdown.menu.show,
.menu-sub-dropdown.show[data-popper-placement] {
 max-height: 650px;
  min-height: 300px;
  //  overflow-y: auto;
}
.apexcharts-menu-icon {
  display: none;
}

.form-select-sm {
  background-color: #f5f8fa !important;
}
.css-13cymwt-control,
.css-t3ipsp-control,
.css-t3ipsp-control:focus,
.css-t3ipsp-control:active .css-13cymwt-control:focus,
.css-13cymwt-control:active {
  background-color: #f5f8fa !important;
  outline: 0 !important;
  padding: 5px;
}

.view-ticket-tab ul.nav-tabs {
  border-color: transparent !important;
}
.view-ticket-tab .cls-btn {
  position: absolute;
  right: 20px;
  top: 15px;
}
.view-ticket-tab ul li {
  Button {
    color: #333333 !important;
    font-size: 18px;
    padding: 15px 18px;
    font-weight: 600;
    background-color: transparent !important;
    border: 0 !important;
    border-bottom: 3px solid transparent;
  }
  .active {
    border-bottom: 3px solid #009ef7 !important;
    color: #009ef7 !important;
  }
}
.view-ticket-tab form {
  background-color: #fff !important;
  padding: 25px;
}
.view-ticket-tab form h2 {
  background-color: #f1f1f1;
  font-size: 18px !important;
  color: #505050 !important;
  padding: 15px 20px;
  margin-bottom: 15px;
}

.graph-left-tkt-lbl {
  position: absolute;
  transform: rotate(270deg);
  left: -86px;
  top: 50%;
  font-size: 13px;
  text-align: center;
}

.fxd-higt-tbl {
  .card {
    .card-body {
      max-height: 350px;
      overflow-y: auto;

table > tbody > tr th a, table > tbody > tr td > div, table > tbody > tr td a {
  font-weight: normal !important;
  color:inherit !important;
  // padding-left:15px;
}

table > thead > tr th {
  font-weight: 600 !important;
  color:#000 !important;
}

    }
  }
}

.table.sentreceiveparts-report >  tbody > tr td, 
.table.supportticketarchives-report >  tbody > tr td, 
.table.claimarchives-report >  tbody > tr td,
.table.pendingtoreceiveparts-report >  tbody > tr td,
.table.supportticketsanalysis-report >  tbody > tr td
 {
  padding-top:1.2rem !important;
  padding-bottom:1.2rem !important;
}

.cust-pagination .MuiInputBase-input {margin-top:-10px;}
.cust-pagination svg {margin-top:-7px;}


.upld-img > div { height: 100px; width: 150px; display:flex; align-items: center ; margin-right:15px; border:1px solid #f5f5f5; position: relative;} 
.upld-img > div img {max-width: 100%;}
.upld-img > div span {  background-color: #fff;
  color: #000;
  font-size: 21px;
  width: 25px;
  height: 25px;
  line-height: 23px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;}

  .form-switch .form-check-input {
    width: 2.2rem !important;
  margin: 0rem !important;
  }
  .form-check-input{
    height: 1.2rem !important;
  }
  .form-switch {
    margin-left: 0rem !important;
    padding: .1rem !important;
  }
  .form-check {
    margin-bottom:0rem !important ;
  }
  /* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
